import { firebase } from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDU5cotmwj8dXwibkKclpigq47SEkLD4Ls",
  authDomain: "ella-project-d7c57.firebaseapp.com",
  projectId: "ella-project-d7c57",
  storageBucket: "ella-project-d7c57.appspot.com",
  messagingSenderId: "805227821596",
  appId: "1:805227821596:web:5800d7d50a357f16f37dd9",
  measurementId: "G-ENS709ZVQP"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();

const firestore = firebase.firestore;

// collection references
const usersCollection = db.collection("users");
const companiesCollection = db.collection("companies");
const surveysCollection = db.collection("surveys");
const actionPlansCollection = db.collection("actionPlans");

// const sectionsCollection = id =>
//   surveysCollection.doc(id).collection("sections");

// const questionsCollection = id =>
//   surveysCollection.doc(id).collection("questions");

const questionsCollection = id =>
  surveysCollection.doc(id).collection("questions");
const sectionsCollection = id =>
  surveysCollection.doc(id).collection("sections");

const scaleCollection = id => surveysCollection.doc(id).collection("scale");

const filtersCollection = id => surveysCollection.doc(id).collection("filters");

const writtenAnswersCollection = surveyId =>
  surveysCollection.doc(surveyId).collection("writtenAnswers");
const answersCollection = surveyId =>
  surveysCollection.doc(surveyId).collection("answers");
const levelsCollection = surveyId =>
  surveysCollection.doc(surveyId).collection("hierarchyLevels");
const optionsCollection = surveyId =>
  surveysCollection.doc(surveyId).collection("hierarchyOptions");
const categoriesCollection = surveyId =>
  surveysCollection.doc(surveyId).collection("categories");

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  companiesCollection,
  surveysCollection,
  actionPlansCollection,
  storage,
  functions,
  firestore,
  answersCollection,
  writtenAnswersCollection,
  levelsCollection,
  optionsCollection,
  categoriesCollection,
  sectionsCollection,
  questionsCollection,
  scaleCollection,
  filtersCollection
};
